import React from 'react';
import { graphql, PageProps } from 'gatsby';
import '../../../mybulma/css/mystyles.css';
import FooterContent from '../components/footer-content';
import HeaderContent from '../components/header-content';
import Seo from '../components/seo';
import { PageIndexQuery } from '../../graphql-types';

const Index = ({ data }: PageProps<PageIndexQuery>): JSX.Element => {
  const siteTitle = data.site?.siteMetadata?.title ?? '';

  return (
    <section className="hero is-fullheight is-primary">
      <Seo
        title="Home"
      />
      <header className="has-background-primary hero-head">
        <HeaderContent blogUrl="https://tech-blog.re-arc-lab.jp/" />
      </header>
      <main>
        <div className="hero-body">
          <div className="container">
            <h1 className="title">{siteTitle}</h1>
            <p className="subtitle">
              <strong>Re</strong>
              {' '}
              Architect A Life
            </p>
          </div>
        </div>

      </main>
      <footer className="hero-foot">
        <FooterContent blogUrl="https://tech-blog.re-arc-lab.jp/" />
      </footer>
    </section>
  );
};

export default Index;

export const pageQuery = graphql`
  query PageIndex {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
